export const addTestIdProp = (testId) => {
  return { 'data-testid': testId }
}

export const addIdProp = (id) => ({ id })

export const titleCaps = (title, lowercaseFirst = true) => {
  if (!title) return ''

  if (lowercaseFirst) {
    title = `${title} `.toLowerCase()
  }
  const sentence = title.split(' ').filter((item) => !!item)
  for (let i = 0; i < sentence.length; i += 1) {
    sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1)
  }

  return sentence.join(' ')
}

export const removeHtmlFromString = (data) => {
  if (!data) return
  return data.replace(/(<([^>]+)>)/gi, ' ')
}

export const toQueryString = (paramsObject) => {
  return new URLSearchParams(paramsObject).toString()
}

export const toggleValueFromString = (string = '', value) => {
  const stringAsArray = string ? string.split('_') : []
  const index = stringAsArray.indexOf(value)
  if (index > -1) {
    stringAsArray.splice(index, 1)
  } else {
    stringAsArray.push(value)
  }
  stringAsArray.sort()
  return stringAsArray.length ? stringAsArray.join('_') : ''
}

export const removeValueFromString = (string = '', value, changeToLowerCase = true) => {
  const stringAsArray = string ? string.toString().split('_') : []
  const index = stringAsArray.indexOf(changeToLowerCase ? value.toLowerCase() : value)
  if (index > -1) {
    stringAsArray.splice(index, 1)
  }
  stringAsArray.sort()
  return stringAsArray.length ? stringAsArray.join('_') : ''
}

export const range = (from, to) => {
  return Array.from(
    { length: (Number(to) - Number(from) + 1) },
    (v, k) => Number(from) + k,
  )
}
